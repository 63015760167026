import React, { useState, useEffect } from 'react'
import { Link, navigate, graphql } from 'gatsby'
import styled, { DefaultTheme, css } from 'styled-components'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import {
  toCssPrefix,
  FormSignUp,
  Image,
  breakpoints,
  FormSignUpData,
  useShoppingCart,
  useAuth,
  hexToRGBA,
} from '@ecommerce/shared'
import Layout from '../../components/Layout/LayoutStaticPage'
import { Icon } from '../../components/Icon/Icon'
import { formatRedirectURL, extractPageData } from '../../components/SignUp/utils'
import { SignupPage as Props } from '../../types/PgPages'
import { sendPageViewEvent } from '../../utils/events'

type StyledProps = { theme: DefaultTheme }

const { cssPrefix, toPrefix } = toCssPrefix('SignupPage__')

const themeColor = (name: keyof DefaultTheme['colors']) => ({ theme }: StyledProps) => theme.colors?.[name]
const errorStyles = () => {
  return css`
    color: ${themeColor('pink')};

    svg {
      fill: ${themeColor('pink')};
    }
  `
}

const Wrapper = styled.div<{ bgImage?: string }>`
  background: ${({ bgImage }) => `url(${bgImage})`};
  background-size: cover;
  background-repeat: no-repeat;
  color: ${themeColor('white')};

  .${cssPrefix} {
    &content {
      padding: 40px 35px 65px;
      width: 100%;

      &__left {
        h2 {
          font-size: 24px;
          text-align: center;
          margin-bottom: 45px;
        }
      }

      &__right {
        margin-top: 60px;

        h2 {
          display: none;
        }
      }
    }

    &form {
      padding: 0;
      background: transparent;
      color: ${themeColor('white')};
      overflow: visible;

      &__subtitle {
        font-size: 18px;
        font-weight: 700;
      }

      &__error_message,
      &__input.status--error label,
      &__input.status--error .TextField__help,
      &__select.status--error label,
      &__select.status--error .TextField__help,
      &__terms-error {
        ${errorStyles}
      }

      &__input,
      &__select {
        color: ${themeColor('white')};

        input {
          &:disabled {
            background: ${({ theme }) => hexToRGBA(theme.colors.grey80, 0.75)};
          }
        }

        label,
        .TextField__help.status--info {
          color: inherit;
        }

        .TextField__help.status--info {
          svg {
            fill: ${themeColor('white')};
          }
        }

        &.status--error {
          input,
          .Select__handle {
            border-color: ${themeColor('pink')};
          }
        }
      }

      &__terms-checkbox {
        font-size: 14px;

        input {
          border-color: ${themeColor('white')};

          &:checked {
            background: ${themeColor('white')};

            &::after {
              border-color: ${themeColor('green')};
            }
          }
        }

        a {
          color: inherit;
          text-decoration-color: inherit;
        }
      }
    }
  }

  @media screen and (${breakpoints.tabletPortrait.min}) {
    .${cssPrefix} {
      &content {
        padding: 45px 65px;

        &__left {
          h2 {
            font-size: 34px;
            max-width: 400px;
            margin: 0 auto 80px;
          }
        }

        &__right {
          margin-top: 70px;
        }
      }

      &form {
        max-width: 360px;
        margin: auto;
      }
    }
  }

  @media screen and (${breakpoints.desktop.min}) {
    .${cssPrefix} {
      &form {
        min-width: 360px;
        max-width: 420px;
      }

      &content {
        min-height: calc(100vh - 212px);
        max-width: 1455px;
        position: relative;
        padding: 120px 135px 60px;
        display: flex;
        justify-content: space-between;
        gap: 140px;

        &__left {
          h2 {
            display: none;
          }
        }

        &__right {
          h2 {
            margin: 100px 0 0;
            text-align: center;
            font-size: 34px;
            display: block;
          }
        }
      }
    }
  }
`

const BackButton = styled(Link)<{ disabled?: boolean }>`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 14px;
  margin-bottom: 60px;
  color: ${themeColor('white')};
  text-decoration: none;
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};
  opacity: ${({ disabled }) => (disabled ? '0.5' : '1')};

  svg {
    margin-right: 5px;
  }

  @media screen and (${breakpoints.desktop.min}) {
    position: absolute;
    top: 45px;
    left: 65px;
  }
`

const IconItem = styled.div`
  margin-bottom: 60px;
  text-align: center;
  font-weight: 700;
  font-size: 18px;

  img {
    display: block;
    width: 120px;
    height: 120px;
    margin: auto auto 25px;
  }

  @media screen and (${breakpoints.tabletPortrait.min}) {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    font-size: 24px;
    text-align: left;
    max-width: 680px;

    img {
      margin: 0 45px 0 0;
    }
  }
`

const SignupPage = ({ data }: Props) => {
  const [loading, setLoading] = useState(false)
  const onFinish = async (formData: FormSignUpData) => {
    await navigate('/registro/confirmacion', { state: { formData } })
  }
  const { getConnectifCart } = useShoppingCart()
  const { getEntityInfo } = useAuth()

  useEffect(() => {
    const entityInfo = getEntityInfo()
    const cart = getConnectifCart()
    sendPageViewEvent({ cart, entityInfo, title: 'Registro' })
  }, [])

  const { pageData, template: pageTemplate } = extractPageData(data)
  const icons = pageTemplate.textAndIconItems

  return (
    <Wrapper bgImage={pageTemplate?.backgroundImage?.file?.url} className={cssPrefix}>
      <Layout title={pageData?.title}>
        <div className={toPrefix('content')}>
          <BackButton disabled={loading} target="_blank" to="/">
            <Icon size={20} iconId="arrow_left" />
            Ir al Inicio
          </BackButton>
          <div className={toPrefix('content__left')}>
            {pageTemplate.pageHeading && <h2>{pageTemplate.pageHeading}</h2>}
            <div className={toPrefix('form-wrapper')}>
              <FormSignUp
                title={null}
                showBottomContent={false}
                showConfirmation={false}
                className={toPrefix('form')}
                setLoading={setLoading}
                loading={loading}
                onFinished={onFinish}
                onSend={() => setLoading(true)}
                onError={() => setLoading(false)}
                onFormatRedirectURL={({ city }) => formatRedirectURL(Number(city))}
                disableInputs={loading}
              />
            </div>
          </div>
          <div className={toPrefix('content__right')}>
            {icons?.map((el) => (
              <IconItem key={el.contentful_id}>
                <Image alt={el.contentful_id} src={el.icon.file.url} />
                {documentToReactComponents(el.text.json)}
              </IconItem>
            ))}
            {pageTemplate.pageHeading && <h2>{pageTemplate.pageHeading}</h2>}
          </div>
        </div>
      </Layout>
    </Wrapper>
  )
}

export default SignupPage

export const query = graphql`
  query SignupPage($signupId: String!) {
    allContentfulPgPage(filter: { contentful_id: { eq: $signupId } }) {
      edges {
        node {
          title
          description
          keywords
          template {
            ... on ContentfulTmSignup {
              contentful_id
              pageHeading
              backgroundImage {
                file {
                  url
                }
              }
              textAndIconItems {
                contentful_id
                icon {
                  file {
                    url
                  }
                }
                text {
                  json
                }
              }
            }
          }
        }
      }
    }
  }
`
